import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type Props = {
  show: boolean;
  onClose: () => void;
  onSuccess: () => void;
};
const HasCompletedProfileDialog = ({ show, onClose, onSuccess }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  
  const takeMeToMyProfile = useCallback(() => {
    onSuccess();
    navigate("/profile/edit");
  }, [navigate]);

  return (
    <Dialog
      open={show}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{t('profile.dialog.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t('profile.dialog.message')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('profile.dialog.close')}</Button>
        <Button onClick={takeMeToMyProfile} autoFocus>
            {t('profile.dialog.take-me')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default HasCompletedProfileDialog;
