import { formatDate, formatPackageStatus } from '../lib/utils/formatting';
import { Package } from '../lib/models/Package';
import { t } from 'i18next';
import { TableConfiguration } from '../components/Table';

export const PACKAGE_TABLE_CONFIGURATION = (translationService: typeof t) =>
  ({
    trackingNumber: {
      numeric: false,
      label: translationService('labels.tracking-number'),
      sortable: true,
      cellProps: {
        component: 'th',
        scope: 'row',
      },
      isKey: true,
    },
    dateAdded: {
      numeric: false,
      label: translationService('labels.date'),
      sortable: true,
      valueFormatter: (pkg: Package) => formatDate(pkg.dateAdded),
    },
    userSuffix: {
      numeric: false,
      label: translationService('labels.user-id'),
      sortable: true,
    },
    invoiceId: {
      numeric: false,
      label: translationService('labels.invoice-id'),
      valueFormatter: (pkg: Package) => (pkg.invoiceId ? translationService('global.yes') : 'No'),
    },
    description: {
      numeric: false,
      label: translationService('labels.description'),
    },
    total: {
      numeric: true,
      cellProps: {
        align: 'right',
      },
      label: translationService('labels.total'),
      sortable: true,
      valueFormatter: (pkg: Package) =>
        pkg.total ? translationService('format.price', { value: pkg.total }) : 'N/A',
    },
    status: {
      numeric: false,
      label: translationService('labels.status'),
      sortable: true,
      valueFormatter: (pkg: Package) => formatPackageStatus(pkg.status, t),
    },
  } as TableConfiguration<Package>);
