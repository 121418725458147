import { LockOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Avatar,
  Box,
  Container,
  CssBaseline, TextField,
  Typography
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AuthClient from '../../lib/clients/AuthClient';
import UserClient from '../../lib/clients/UserClient';
import useAuthContext from '../../lib/contexts/auth/useAuthContext';
import useUsersContext from '../../lib/contexts/users/useUsersContext';

export interface Props { }

const Login: FC<Props> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { handleSubmit, control } = useForm({
    defaultValues: {
      username: '',
      password: '',
    },
  });

  const [{ auth }, dispatch] = useAuthContext();
  const [, userDispatch] = useUsersContext();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = (data: any) => {
    setIsLoading(true);
    const { ...rest } = data;
    AuthClient.login(rest, dispatch)
      .then(() => {
        UserClient.getUser(userDispatch);
        navigate('/');
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (auth) {
      navigate('/');
    }
  }, [auth]);

  return (
    <Container maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlined />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t('login.login')}
        </Typography>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
          <Controller
            name="username"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value }, formState: { errors } }) => (
              <TextField
                margin="normal"
                required
                disabled={isLoading}
                error={!!errors?.username}
                fullWidth
                label={t('labels.email')}
                autoComplete="email"
                onChange={onChange}
                value={value}
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value }, formState: { errors } }) => (
              <TextField
                margin="normal"
                required
                fullWidth
                disabled={isLoading}
                type="password"
                error={!!errors?.password}
                label={t('labels.password')}
                autoComplete="current-password"
                onChange={onChange}
                value={value}
              />
            )}
          />
          <LoadingButton
            loading={isLoading}
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {t('login.sign-in')}
          </LoadingButton>
        </Box>
      </Box>
    </Container>
  );
};

Login.displayName = 'Login';

export default Login;
