/* eslint-disable react/no-unused-prop-types */
import { Alert, AlertProps, Box, Fade } from '@mui/material';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';

type DialogType = {
  id: string;
  severity?: AlertProps['severity'];
  message: string;
};
export type DialogContextType = {
  dialogs: DialogType[];
  addDialog: (d: Omit<DialogType, 'id'>) => void;
  removeById: (d: string) => void;
};

export const DialogContext = createContext<DialogContextType>({} as DialogContextType);

type Props = {
  children: JSX.Element | JSX.Element[];
};
export const DialogProvider = ({ children }: Props) => {
  const [dialogs, setDialogs] = useState<DialogType[]>([]);

  const removeById = useCallback((id: string) => {
    setDialogs((prev) => prev.filter((t) => t.id !== id));
  }, []);

  const addDialog = useCallback(
    (newDialog: Omit<DialogType, 'id'>) => {
      const id = uuid();
      setDialogs((prev) => [...prev, { ...newDialog, id }]);
      setTimeout(() => {
        removeById(id);
      }, 3000);
    },
    [removeById]
  );

  const value = useMemo(
    () => ({
      dialogs,
      addDialog,
      removeById,
    }),
    [dialogs]
  );

  return <DialogContext.Provider value={value}>{children}</DialogContext.Provider>;
};

export const useDialog = () => useContext(DialogContext);
export const GlobalDialog = () => (
  <DialogContext.Consumer>
    {({ dialogs = [], removeById }: DialogContextType) => (
      <Box sx={{ position: 'fixed', width: '30%', right: '2%', top: '5%', zIndex: 1200 }}>
        {dialogs.map((d) => (
          <Fade in key={d.id}>
            <Alert
              onClose={() => removeById(d.id)}
              severity={d.severity ?? 'success'}
              sx={{ mb: 2 }}
            >
              {d.message}
            </Alert>
          </Fade>
        ))}
      </Box>
    )}
  </DialogContext.Consumer>
);
