import { Box, Container, Typography } from '@mui/material';

import { FC } from 'react';

export interface Props { }

const Footer: FC<Props> = () => (
  <Box
    component="footer"
    sx={{
      py: 3,
      px: 2,
      backgroundColor: (theme) =>
        theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
    }}
  >
    <Container>
      <Typography variant="body2" color="text.secondary" align="center">
        &quot;Chantobox llegará cuando tenga que llegar&quot;
      </Typography>
    </Container>
  </Box>
);

Footer.displayName = 'Footer';

export default Footer;
