import { Auth } from '../../models/Auth';
import { ReactNode } from 'react';

export interface AuthContextState {
  auth: Auth | null;
  isFetching: boolean;
  hasFetched: boolean;
}

export interface AuthContextProps {
  children: ReactNode;
}

export enum AuthContextActionTypes {
  Login = 'Login',
  LoginSuccess = 'LoginSuccess',
  LoginError = 'LoginError',
  Logout = 'Logout',
  RoleUpdate = 'RoleUpdate',
}

export type AuthContextAction =
  | { type: AuthContextActionTypes.Login }
  | { type: AuthContextActionTypes.LoginSuccess; payload: Auth }
  | { type: AuthContextActionTypes.LoginError }
  | { type: AuthContextActionTypes.Logout }
  | { type: AuthContextActionTypes.RoleUpdate, payload: Partial<Auth> };

export type AuthContextDispatch = (action: AuthContextAction) => void;

export type AuthContextType = [AuthContextState, AuthContextDispatch];
