import { BaseSyntheticEvent, ReactNode, useCallback, useState } from 'react';
import {
  Container,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { debounce } from 'lodash-es';
import { Search } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

export type PageHeaderChildProps = {
  searchKeyWord?: string;
};
type Props = {
  title?: string;
  searchEnabled?: boolean;
  HeaderElements?: ReactNode;
  disabledFields?: boolean;
  children: (child: PageHeaderChildProps) => JSX.Element;
};

const PageHeader = ({
  title,
  children,
  HeaderElements,
  searchEnabled,
  disabledFields = false,
}: Props) => {
  const [searchKeyWord, setSearchKeyWord] = useState('');
  const { t } = useTranslation();
  const [searchField, setSearchField] = useState('');
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const onInputChange = useCallback(
    debounce((newValue: string) => {
      if (searchKeyWord.trim() !== newValue.trim()) {
        setSearchKeyWord(newValue);
      }
    }, 300),
    []
  );

  return (
    <Container sx={{ py: 8 }}>
      <Grid container spacing={2} sx={{ mb: 4 }}>
        <Grid item md={4} xs={12}>
          <Typography component="h1" variant="h5">
            {title}
          </Typography>
        </Grid>

        {(searchEnabled || HeaderElements) && (
          <Grid item md={8} xs={12} container display="flex" justifyContent="flex-end">
            {searchEnabled && (
              <TextField
                label={t('global.search', {})}
                value={searchField}
                sx={{
                  ...(isSmallScreen ? { mb: 3 } : { mr: 3 }),
                }}
                onChange={({ target }: BaseSyntheticEvent) => {
                  setSearchField(target.value);
                  onInputChange(target.value);
                }}
                disabled={disabledFields}
                {...(isSmallScreen && { fullWidth: true })}
                InputProps={{
                  fullWidth: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
            )}
            {HeaderElements && HeaderElements}
          </Grid>
        )}
      </Grid>
      {children({ searchKeyWord })}
    </Container>
  );
};

export default PageHeader;
