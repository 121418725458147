import {
  UsersContextAction,
  UsersContextActionTypes,
  UsersContextState,
} from './UsersContext.types';

export const UsersContextReducer = (
  state: UsersContextState,
  action: UsersContextAction
): UsersContextState => {
  switch (action.type) {
    case UsersContextActionTypes.GetUser:
      return { ...state, isFetchingUsers: true };
    case UsersContextActionTypes.GetUserSuccess:
      return { ...state, isFetchingUsers: false, user: action.payload };
    case UsersContextActionTypes.GetUserError:
      return { ...state, isFetchingUsers: false };
    case UsersContextActionTypes.GetAllUsers:
      return { ...state, isFetchingUsers: true };
    case UsersContextActionTypes.GetAllUsersSuccess:
      return { ...state, isFetchingAllUsers: false, allUsers: action.payload };
    case UsersContextActionTypes.GetAllUsersError:
      return { ...state, isFetchingAllUsers: false };
    default:
      return state;
  }
};
