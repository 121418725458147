import { FC, useEffect, useMemo } from 'react';
import { INVOICE_TABLE_CONFIGURATION } from '../../constants/invoices';
import InvoiceClient from '../../lib/clients/InvoiceClient';
import { isEmpty } from 'lodash-es';
import PageHeader from '../../components/PageHeader';
import useInvoiceContext from '../../lib/contexts/invoices/useInvoiceContext';
import { useTranslation } from 'react-i18next';
import ViewWithTable from '../../views/ViewWithTable';

const AllInvoices: FC = () => {
  const { t } = useTranslation();
  const [{ allInvoices = [], isFetchingAllInvoices }, dispatch] = useInvoiceContext();

  useEffect(() => {
    InvoiceClient.getAllInvoices(dispatch);
  }, [dispatch]);

  const tableConfiguration = useMemo(() => INVOICE_TABLE_CONFIGURATION(t), [t]);

  return (
    <PageHeader
      searchEnabled
      title={t('invoices.all-invoices')}
      disabledFields={isFetchingAllInvoices || isEmpty(allInvoices)}
    >
      {(props) => (
        <ViewWithTable
          {...props}
          values={allInvoices}
          searchProp="invoiceId"
          tableConfiguration={tableConfiguration}
          isFetching={isFetchingAllInvoices}
          initialOrderBy="dateAdded"
          initialSorting="desc"
          rowKey="invoiceId"
        />
      )}
    </PageHeader>
  );
};

AllInvoices.displayName = 'AllInvoices';

export default AllInvoices;
