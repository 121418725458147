import { FC, useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash-es';
import PageHeader from '../../components/PageHeader';
import { USER_TABLE_CONFIGURATION } from '../../constants/users';
import UserClient from '../../lib/clients/UserClient';
import { useTranslation } from 'react-i18next';
import useUsersContext from '../../lib/contexts/users/useUsersContext';
import ViewWithTable from '../../views/ViewWithTable';
import { Button, useMediaQuery } from '@mui/material';
import { theme } from '../../lib/constants/theme';
import AddUserModal from '../../components/AddUserModal';

const Users: FC = () => {
  const { t } = useTranslation();
  const [{ allUsers = [], isFetchingAllUsers }, dispatch] = useUsersContext();
  const [open, setOpen] = useState<boolean>(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    UserClient.getUsers(dispatch);
  }, [dispatch]);

  const tableConfiguration = useMemo(() => USER_TABLE_CONFIGURATION(t), [t]);

  return (
    <>
      <PageHeader
        searchEnabled
        title={t('users.users')}
        disabledFields={isFetchingAllUsers || isEmpty(allUsers)}
        HeaderElements={
          <Button
            type="button"
            variant="contained"
            disabled={isFetchingAllUsers}
            onClick={() => {
              setOpen(true);
            }}
            {...(isSmallScreen && { fullWidth: true })}
          >
            {t('users.add-user')}
          </Button>
        }
      >
        {(props) => (
          <ViewWithTable
            {...props}
            values={allUsers}
            searchProp="name"
            tableConfiguration={tableConfiguration}
            isFetching={isFetchingAllUsers}
            initialOrderBy="name"
            initialSorting="asc"
            rowKey="uuid"
          />
        )}
      </PageHeader>
      <AddUserModal open={open} setOpen={setOpen} />
    </>
  );
};

Users.displayName = 'Users';

export default Users;
