export enum InvoiceStatus {
  PENDING_PAYMENT = 0,
  PAID = 1,
}

export interface Invoice {
  dateAdded: number;
  invoiceId: string;
  packages: string[];
  status: InvoiceStatus;
  subTotal: number;
  tip: number;
  total: number;
  userId: string;
  userSuffix: string;
}

export type SortableKeys = Pick<
  Invoice,
  'dateAdded' | 'invoiceId' | 'status' | 'subTotal' | 'total' | 'userId'
>;
