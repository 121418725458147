import {
  InvoiceContextAction,
  InvoiceContextActionTypes,
  InvoiceContextState,
} from './InvoiceContext.types';

export const InvoiceContextReducer = (
  state: InvoiceContextState,
  action: InvoiceContextAction
): InvoiceContextState => {
  switch (action.type) {
    case InvoiceContextActionTypes.GetInvoices:
      return { ...state, isFetchingInvoices: true };
    case InvoiceContextActionTypes.GetInvoicesSuccess:
      return { ...state, isFetchingInvoices: false, invoices: action.payload };
    case InvoiceContextActionTypes.GetInvoicesError:
      return { ...state, isFetchingInvoices: false };
    case InvoiceContextActionTypes.GetAllInvoices:
      return { ...state, isFetchingAllInvoices: true };
    case InvoiceContextActionTypes.GetAllInvoicesSuccess:
      return { ...state, isFetchingAllInvoices: false, allInvoices: action.payload };
    case InvoiceContextActionTypes.GetAllInvoicesError:
      return { ...state, isFetchingAllInvoices: false };
    default:
      return state;
  }
};
