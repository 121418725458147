export enum PackageStatus {
  PENDING_ARRIVAL = 0,
  AWAITING_PICKUP = 1,
  DELIVERED = 2,
  DONE = 3,
}

export interface Package {
  userId: string;
  userSuffix: string;
  trackingNumber: string;
  description: string;
  status: PackageStatus;
  total: number;
  dateAdded: number;
  invoiceId: string | null;
}

export type SortableKeys = Pick<
  Package,
  'dateAdded' | 'userId' | 'userSuffix' | 'trackingNumber' | 'status' | 'total'
>;
