import { createContext, FC, useReducer } from 'react';
import { UsersContextProps, UsersContextState, UsersContextType } from './UsersContext.types';
import { UsersContextReducer } from './reducer';

const initialState: UsersContextState = {
  user: null,
  isFetchingUsers: false,
  allUsers: [],
  isFetchingAllUsers: false,
};

const UsersContext = createContext<UsersContextType | undefined>(undefined);

export const UsersProvider: FC<UsersContextProps> = ({ children }) => {
  const value = useReducer(UsersContextReducer, initialState);

  return <UsersContext.Provider value={value}>{children}</UsersContext.Provider>;
};

export default UsersContext;
