import axios from 'axios';
import { API_BASE_URL } from '../constants/api';

export type ExpressResponse = { result: boolean; description?: string };

class ExpressClient {
  url = `${API_BASE_URL}/express`;

  requestExpress(
    userId: string,
    onSuccess: (data: ExpressResponse) => void,
    onError?: (err: any) => void,
    onFinally?: () => void
  ) {
    axios
      .post<ExpressResponse>(this.url, { userId })
      .then(({ data }) => {
        onSuccess?.(data);
      })
      .catch((err) => {
        onError?.(err);
      })
      .finally(() => onFinally?.());
  }
}

export default new ExpressClient();
