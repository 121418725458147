export enum Breakpoint {
  XS = 'isXs',
  MD = 'isMd',
  LG = 'isLg',
  XL = 'isXl',
}

export interface BreakpointEntry {
  name: Breakpoint;
  query: string;
  minWidth: number;
}

export const BREAKPOINTS: Array<BreakpointEntry> = [
  { name: Breakpoint.XS, query: '(max-width: 767px)', minWidth: 0 },
  { name: Breakpoint.MD, query: '(max-width: 1023px)', minWidth: 768 },
  { name: Breakpoint.LG, query: '(max-width: 1439px)', minWidth: 1024 },
  { name: Breakpoint.XL, query: '(min-width: 1440px)', minWidth: 1440 },
];
