import {
  ProfileContextAction,
  ProfileContextActionTypes,
  ProfileContextState,
} from './ProfileContext.types';

export const profileContextReducer = (
  state: ProfileContextState,
  action: ProfileContextAction
): ProfileContextState => {
  switch (action.type) {
    case ProfileContextActionTypes.GetProfile:
      return { ...state, isFetching: true };
    case ProfileContextActionTypes.GetProfileSuccess:
      return { ...state, isFetching: false, profile: action.payload };
    case ProfileContextActionTypes.GetProfileError:
      return { ...state, isFetching: false };
    case ProfileContextActionTypes.EditProfile:
      return { ...state, isUpdating: true };
    case ProfileContextActionTypes.EditProfileSuccess:
      return { ...state, isUpdating: false };
    case ProfileContextActionTypes.EditProfileError:
      return { ...state, isUpdating: false };
    default:
      return state;
  }
};
