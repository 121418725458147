import { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import useAuthContext from '../contexts/auth/useAuthContext';

interface Props {
  children: ReactNode;
}

const ProtectedRoute: FC<Props> = ({ children }) => {
  const [{ auth }] = useAuthContext();
  if (!auth || !auth.token || !auth.userId) {
    return <Navigate to="/login" />;
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;

};

export default ProtectedRoute;
