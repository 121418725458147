import axios, { AxiosResponse } from 'axios';
import {
  ProfileContextActionTypes,
  ProfileContextDispatch,
} from '../contexts/profile/ProfileContext.types';

import { API_BASE_URL } from '../constants/api';
import { AuthLocalStorageKeys } from '../constants/auth';
import { isFinite } from 'lodash-es';
import { Profile } from '../models/Profile';

type AxiosResponseType = AxiosResponse<Profile> & { response?: any };
class ProfileClient {
  url = `${API_BASE_URL}/profile`;

  getProfile(dispatch: ProfileContextDispatch) {
    dispatch({ type: ProfileContextActionTypes.GetProfile });
    axios
      .get<Profile>(`${this.url}/${localStorage.getItem(AuthLocalStorageKeys.userId)}`)
      .then(({ data }) => {
        dispatch({ type: ProfileContextActionTypes.GetProfileSuccess, payload: data });
      })
      .catch(() => {
        dispatch({ type: ProfileContextActionTypes.GetProfileError });
      });
  }

  editProfile(
    dispatch: ProfileContextDispatch,
    { id, ...profile }: Partial<Profile> & Pick<Profile, 'id'>
  ): Promise<void> {
    dispatch({ type: ProfileContextActionTypes.EditProfile });
    return axios
      .patch<Profile>(`${this.url}/${id}`, profile)
      .then(({ data, response }: AxiosResponseType) => {
        if (isFinite(response?.status) && response.status >= 300) {
          return Promise.reject();
        }
        return Promise.resolve(
          dispatch({ type: ProfileContextActionTypes.EditProfileSuccess, payload: data })
        );
      })
      .catch(() => Promise.reject(dispatch({ type: ProfileContextActionTypes.EditProfileError })));
  }
}

export default new ProfileClient();
