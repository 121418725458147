import InvoiceContext from './InvoiceContext';
import { InvoiceContextType } from './InvoiceContext.types';
import { useContext } from 'react';

const useInvoiceContext = (): InvoiceContextType => {
  const context = useContext(InvoiceContext);

  if (context === undefined) {
    throw new Error('useInvoiceContext must be used within a InvoiceProvider');
  }

  return context;
};

export default useInvoiceContext;
