import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FC } from 'react';

export interface Props {
    open: boolean;
    handleClose: () => void;
    handleConfirm: () => void;
    message: string;
    title: string;
}

const ConfirmationDialog: FC<Props> = ({ open, handleClose, handleConfirm, message, title }) => (
    <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
            <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} color="primary">
                Cancelar
            </Button>
            <Button onClick={handleConfirm} color="primary" autoFocus>
                Aceptar
            </Button>
        </DialogActions>
    </Dialog>
);

export default ConfirmationDialog;