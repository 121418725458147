import { useContext } from 'react';
import UsersContext from './UsersContext';
import { UsersContextType } from './UsersContext.types';

const useUsersContext = (): UsersContextType => {
  const context = useContext(UsersContext);

  if (context === undefined) {
    throw new Error('useUsersContext must be used within a UsersProvider');
  }

  return context;
};

export default useUsersContext;
