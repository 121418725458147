import { Invoice } from '../../models/Invoice';
import { ReactNode } from 'react';

export interface InvoiceContextState {
  invoices: Invoice[];
  isFetchingInvoices: boolean;
  allInvoices: Invoice[];
  isFetchingAllInvoices: boolean;
}

export interface InvoiceContextProps {
  children: ReactNode;
}

export enum InvoiceContextActionTypes {
  GetInvoices = 'GetInvoices',
  GetInvoicesSuccess = 'GetInvoicesSuccess',
  GetInvoicesError = 'GetInvoicesError',
  GetAllInvoices = 'GetAllInvoices',
  GetAllInvoicesSuccess = 'GetAllInvoicesSuccess',
  GetAllInvoicesError = 'GetAllInvoicesError',
}

export type InvoiceContextAction =
  | { type: InvoiceContextActionTypes.GetInvoices }
  | { type: InvoiceContextActionTypes.GetInvoicesSuccess; payload: Invoice[] }
  | { type: InvoiceContextActionTypes.GetInvoicesError }
  | { type: InvoiceContextActionTypes.GetAllInvoices }
  | { type: InvoiceContextActionTypes.GetAllInvoicesSuccess; payload: Invoice[] }
  | { type: InvoiceContextActionTypes.GetAllInvoicesError };

export type InvoiceContextDispatch = (action: InvoiceContextAction) => void;

export type InvoiceContextType = [InvoiceContextState, InvoiceContextDispatch];
