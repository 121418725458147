import {
  UsersContextActionTypes,
  UsersContextDispatch,
} from '../contexts/users/UsersContext.types';

import { API_BASE_URL } from '../constants/api';
import { AuthLocalStorageKeys } from '../constants/auth';
import axios from 'axios';
import { isNil } from 'lodash-es';
import { User } from '../models/User';

class UserClient {
  url = `${API_BASE_URL}/user`;

  getUsers(dispatch: UsersContextDispatch) {
    dispatch({ type: UsersContextActionTypes.GetAllUsers });

    axios
      .get<User[]>(`${this.url}`)
      .then(({ data }) => {
        dispatch({ type: UsersContextActionTypes.GetAllUsersSuccess, payload: data });
      })
      .catch(() => {
        dispatch({ type: UsersContextActionTypes.GetAllUsersError });
      });
  }

  getUser(dispatch: UsersContextDispatch) {
    dispatch({ type: UsersContextActionTypes.GetUser });
    const userId = localStorage.getItem(AuthLocalStorageKeys.userId);
    if (isNil(userId)) {
      dispatch({ type: UsersContextActionTypes.GetUserError });
      return;
    }
    axios
      .get<User>(`${this.url}/${userId}`)
      .then(({ data }) => {
        dispatch({ type: UsersContextActionTypes.GetUserSuccess, payload: data });
      })
      .catch(() => {
        dispatch({ type: UsersContextActionTypes.GetUserError });
      });
  }

  addUser(payload: Partial<User>) {
    return axios.post(`${this.url}`, payload);
  }

  deleteUser(userId: string) {
    return axios.delete(`${this.url}/${userId}`);
  }
}

export default new UserClient();
