import { LoadingButton } from '@mui/lab';
import { Box, Button, useMediaQuery, useTheme } from '@mui/material';
import { isEmpty, isNil, pickBy } from 'lodash-es';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddPackageModal from '../../components/AddPackageModal';
import ConfirmationDialog from '../../components/ConfirmationModal';
import PageHeader from '../../components/PageHeader';
import { PACKAGE_TABLE_CONFIGURATION } from '../../constants/packages';
import ExpressClient, { ExpressResponse } from '../../lib/clients/ExpressClient';
import PackageClient from '../../lib/clients/PackageClient';
import { useDialog } from '../../lib/contexts/dialog';
import usePackageContext from '../../lib/contexts/packages/usePackageContext';
import useUsersContext from '../../lib/contexts/users/useUsersContext';
import ViewWithTable from '../../views/ViewWithTable';

const Dashboard: FC = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const [{ user }] = useUsersContext();
  const theme = useTheme();
  const { addDialog } = useDialog();
  const [expressLoading, setExpressLoading] = useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [{ packages = [], isFetchingPackages }, dispatch] = usePackageContext();
  useEffect(() => {
    PackageClient.getPackages(dispatch);
  }, [dispatch]);

  const tableConfiguration = useMemo(
    () =>
      pickBy(PACKAGE_TABLE_CONFIGURATION(t), (_, key) => key !== 'userId' && key !== 'invoiceId'),
    [t]
  );

  const requestExpress = useCallback(() => {
    if (isNil(user?.uuid)) {
      addDialog({ message: t('global.something-went-wrong'), severity: 'error' });
      return () => { };
    }
    setExpressLoading(true);
    const onSuccess = ({ result, description }: ExpressResponse) => {
      if (result) {
        addDialog({
          message: t('global.successfully', { description: t('express.requested') }),
          severity: 'success',
        });
      } else {
        addDialog({
          message: description ?? t('global.something-went-wrong'),
          severity: 'error',
        });
      }
    };
    const onError = () => {
      addDialog({ message: t('global.something-went-wrong'), severity: 'error' });
    };

    const onFinally = () => {
      setExpressLoading(false);
    };
    return ExpressClient.requestExpress(user?.uuid as string, onSuccess, onError, onFinally);
  }, [user?.uuid]);

  const handleClickOpenConfirmationModal = () => {
    setOpenConfirmation(true);
  };

  const handleCloseConfirmationModal = () => {
    setOpenConfirmation(false);
  };

  const handleConfirmConfirmationModal = () => {
    setOpenConfirmation(false);
    requestExpress();
  };

  return (
    <>
      <PageHeader
        searchEnabled
        title={t('packages.my-packages')}
        disabledFields={isFetchingPackages || isEmpty(packages)}
        HeaderElements={
          <Box
            display="flex"
            justifyContent="end"
            alignItems="center"
            {...(isSmallScreen && { flexGrow: 1 })}
          >
            <Button
              type="button"
              variant="contained"
              disabled={isFetchingPackages}
              sx={{ mr: 2 }}
              onClick={() => {
                setOpen(true);
              }}
              {...(isSmallScreen && { fullWidth: true })}
            >
              {t('packages.add-package')}
            </Button>
            <LoadingButton
              type="button"
              loading={expressLoading}
              variant="contained"
              disabled={isFetchingPackages}
              onClick={handleClickOpenConfirmationModal}
              {...(isSmallScreen && { fullWidth: true })}
            >
              {t('express.request')}
            </LoadingButton>
            <ConfirmationDialog
              open={openConfirmation}
              handleClose={handleCloseConfirmationModal}
              handleConfirm={handleConfirmConfirmationModal}
              title={t('express.requesting-title')}
              message={t('express.requesting-message')}
            />
          </Box>
        }
      >
        {(props) => (
          <ViewWithTable
            {...props}
            values={packages}
            searchProp="trackingNumber"
            tableConfiguration={tableConfiguration}
            isFetching={isFetchingPackages}
            initialOrderBy="dateAdded"
            initialSorting="desc"
            rowKey="trackingNumber"
          />
        )}
      </PageHeader>
      <AddPackageModal open={open} setOpen={setOpen} />
    </>
  );
};

Dashboard.displayName = 'Dashboard';

export default Dashboard;
