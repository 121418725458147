import { createContext, FC, useReducer } from 'react';
import {
  ProfileContextProps,
  ProfileContextState,
  ProfileContextType,
} from './ProfileContext.types';
import { profileContextReducer } from './reducer';

const initialState: ProfileContextState = {
  profile: null,
  isFetching: false,
  isUpdating: false,
};

const ProfileContext = createContext<ProfileContextType | undefined>(undefined);

export const ProfileProvider: FC<ProfileContextProps> = ({ children }) => {
  const value = useReducer(profileContextReducer, initialState);

  return <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>;
};

export default ProfileContext;
