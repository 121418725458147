import {
  PackageContextActionTypes,
  PackageContextDispatch,
} from '../contexts/packages/PackageContext.types';

import { API_BASE_URL } from '../constants/api';
import { AuthLocalStorageKeys } from '../constants/auth';
import axios from 'axios';
import { Package } from '../models/Package';

class PackageClient {
  url = `${API_BASE_URL}/packages`;

  getPackages(dispatch: PackageContextDispatch) {
    dispatch({ type: PackageContextActionTypes.GetPackages });

    axios
      .get<Package[]>(`${this.url}/user/${localStorage.getItem(AuthLocalStorageKeys.userId)}`)
      .then(({ data }) => {
        dispatch({ type: PackageContextActionTypes.GetPackagesSuccess, payload: data });
      })
      .catch(() => {
        dispatch({ type: PackageContextActionTypes.GetAllPackagesError });
      });
  }

  getAllPackages(dispatch: PackageContextDispatch) {
    dispatch({ type: PackageContextActionTypes.GetAllPackages });

    axios
      .get<Package[]>(`${this.url}`)
      .then(({ data }) => {
        dispatch({ type: PackageContextActionTypes.GetAllPackagesSuccess, payload: data });
      })
      .catch(() => {
        dispatch({ type: PackageContextActionTypes.GetAllPackagesError });
      });
  }

  addPackage(payload: Package) {
    return axios.post(`${this.url}`, {
      ...payload,
      userId: localStorage.getItem(AuthLocalStorageKeys.userId),
    });
  }
}

export default new PackageClient();
