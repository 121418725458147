import { Box, Button, Drawer, IconButton, Link, Typography } from '@mui/material';
import { FC, useState } from 'react';

import { Menu } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import useAuthContext from '../../lib/contexts/auth/useAuthContext';
import { HeaderPage } from './Header.types';
import { StyledLogo } from './HeaderStyles';

interface Props {
  pages: HeaderPage[];
  settings: HeaderPage[];
  onLogout(): void;
}

const MobileHeader: FC<Props> = ({ pages, settings, onLogout }) => {
  const { t } = useTranslation();
  const [{ auth }] = useAuthContext();
  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <Box
      justifyContent={auth ? 'space-between' : 'center'}
      display="flex"
      alignItems="center"
      width="100%"
    >
      <Typography
        variant="h6"
        noWrap
        component={RouterLink}
        to="/"
        sx={{
          mr: auth ? 2 : 0,
          display: 'flex',
          fontFamily: 'monospace',
          fontWeight: 700,
          letterSpacing: '.3rem',
          color: 'inherit',
          textDecoration: 'none',
        }}
      >
        <StyledLogo src={logo} alt="Chantobox Logo" />
      </Typography>
      {auth && (
        <>
          <IconButton
            onClick={() => {
              setDrawerOpen(true);
            }}
          >
            <Menu />
          </IconButton>
          <Drawer
            anchor="right"
            open={drawerOpen}
            onClose={() => {
              setDrawerOpen(false);
            }}
          >
            <Box component="nav">
              {pages.map((page) => (
                <Link
                  key={page.href}
                  component={RouterLink}
                  to={{ pathname: page.href }}
                  sx={{ m: 2, display: 'block' }}
                >
                  {t(page.label)}
                </Link>
              ))}
              {settings.map((page) => (
                <Link
                  key={page.href}
                  component={RouterLink}
                  to={{ pathname: page.href }}
                  sx={{ m: 2, display: 'block' }}
                >
                  {t(page.label)}
                </Link>
              ))}
              <Button onClick={onLogout}>{t('global.logout')}</Button>
            </Box>
          </Drawer>
        </>
      )}
    </Box>
  );
};

export default MobileHeader;
