import { Avatar, Box, IconButton, Link, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import { FC, MouseEvent, useState } from 'react';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import useProfileContext from '../../lib/contexts/profile/useProfileContext';
import { HeaderPage } from './Header.types';
import { StyledLogo } from './HeaderStyles';

interface Props {
  pages: HeaderPage[];
  settings: HeaderPage[];
  onLogout(): void;
}

const DesktopHeader: FC<Props> = ({ pages, settings, onLogout }) => {
  const { t } = useTranslation(); 
  const [{ profile }] = useProfileContext();
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <>
      <Typography
        variant="h6"
        noWrap
        component={RouterLink}
        to="/"
        sx={{
          mr: 2,
          display: 'flex',
          fontFamily: 'monospace',
          fontWeight: 700,
          letterSpacing: '.3rem',
          color: 'inherit',
          textDecoration: 'none',
        }}
      >
        <StyledLogo src={logo} alt="Chantobox Logo" />
      </Typography>
      <Box sx={{ flexGrow: 1, display: 'flex' }}>
        {pages.map((page) => (
          <Link
            key={page.href}
            component={RouterLink}
            to={{ pathname: page.href }}
            sx={{ m: 2, color: 'white', display: 'block' }}
          >
            {t(page.label)}
          </Link>
        ))}
      </Box>
      {profile && (
        <Box sx={{ flexGrow: 0 }}>
          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <AccountCircleIcon />
              </Avatar>
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings.map((setting) => (
              <Link key={setting.href} component={RouterLink} to={{ pathname: setting.href }}>
                <MenuItem key={setting.href} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{t(setting.label)}</Typography>
                </MenuItem>
              </Link>
            ))}
            <MenuItem onClick={handleCloseUserMenu}>
              <Typography textAlign="center">
                <Typography onClick={onLogout} textAlign="center">
                  {t('global.logout')}
                </Typography>
              </Typography>
            </MenuItem>
          </Menu>
        </Box>
      )}
    </>
  );
};

export default DesktopHeader;
