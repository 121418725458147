import './lib/clients/axios';
import cx from 'classnames';
import { FC } from 'react';
import Footer from './components/Footer';
import { GlobalDialog } from './lib/contexts/dialog';
import Header from './components/Header';
import { Outlet } from 'react-router-dom';
import RootProvider from './lib/contexts/RootProvider';
import styles from './App.module.scss';
import { theme } from './lib/constants/theme';
import { ThemeProvider } from '@mui/material';

const App: FC = () => (
  <RootProvider>
    <ThemeProvider theme={theme}>
      <GlobalDialog />
      <div className={cx(styles['app'])}>
        <Header />
        <main className={cx(styles['app__main'])}>
          <Outlet />
        </main>
        <Footer />
      </div>
    </ThemeProvider>
  </RootProvider>
);

export default App;
