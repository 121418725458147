import { createContext, FC, useReducer } from 'react';
import {
  PackageContextProps,
  PackageContextState,
  PackageContextType,
} from './PackageContext.types';
import { PackageContextReducer } from './reducer';

const initialState: PackageContextState = {
  packages: [],
  isFetchingPackages: false,
  allPackages: [],
  isFetchingAllPackages: false,
};

const PackageContext = createContext<PackageContextType | undefined>(undefined);

export const PackageProvider: FC<PackageContextProps> = ({ children }) => {
  const value = useReducer(PackageContextReducer, initialState);

  return <PackageContext.Provider value={value}>{children}</PackageContext.Provider>;
};

export default PackageContext;
