import { Avatar, Box, Container, Typography } from '@mui/material';

import { FC } from 'react';
import { formatInitials } from '../../lib/utils/formatting';
import useProfileContext from '../../lib/contexts/profile/useProfileContext';
import { useTranslation } from 'react-i18next';
import useUsersContext from '../../lib/contexts/users/useUsersContext';

const FALLBACK = '\u00A0';

const ProfileDetails: FC = () => {
  const { t } = useTranslation();
  const [{ user }] = useUsersContext();
  const [{ profile }] = useProfileContext();

  return (
    profile && (
      <Container maxWidth="xs" sx={{ p: 0 }}>
        <Box border={2} borderColor="grey.800" borderRadius="3px" p={4}>
          <Box display="flex" justifyContent="center" mb={2}>
            <Avatar sx={{ bgcolor: 'secondary.main', width: 75, height: 75 }}>
              <Typography textAlign="center" variant="h4">
                {formatInitials(profile)}
              </Typography>
            </Avatar>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2" color="grey.700" fontWeight="700">
              {t('labels.name')}
            </Typography>
            <Typography variant="body1" sx={{ overflowWrap: 'break-word' }}>
              {user?.name || FALLBACK}
            </Typography>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2" color="grey.700" fontWeight="700">
              {t('labels.email')}
            </Typography>
            <Typography variant="body1" sx={{ overflowWrap: 'break-word' }}>
              {user?.email || FALLBACK}
            </Typography>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2" color="grey.700" fontWeight="700">
              {t('labels.initials')}
            </Typography>
            <Typography variant="body1" sx={{ overflowWrap: 'break-word' }}>
              {profile?.initials || FALLBACK}
            </Typography>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2" color="grey.700" fontWeight="700">
              {t('labels.phone')}
            </Typography>
            <Typography variant="body1" sx={{ overflowWrap: 'break-word' }}>
              {profile?.phone || FALLBACK}
            </Typography>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2" color="grey.700" fontWeight="700">
              {t('labels.location')}
            </Typography>
            <Typography variant="body1" sx={{ overflowWrap: 'break-word' }}>
              {profile?.location && (
                <a href={`${profile?.location}`} target="_blank" rel="noreferrer">{profile?.location}</a>
              ) || FALLBACK}
            </Typography>
          </Box>
        </Box>
      </Container>
    )
  );
};

export default ProfileDetails;
