import { ReactNode } from 'react';
import { User } from '../../models/User';

export interface UsersContextState {
  user: User | null;
  isFetchingUsers: boolean;
  allUsers: User[];
  isFetchingAllUsers: boolean;
}

export interface UsersContextProps {
  children: ReactNode;
}

export enum UsersContextActionTypes {
  GetUser = 'GetUser',
  GetUserSuccess = 'GetUserSuccess',
  GetUserError = 'GetUserError',
  GetAllUsers = 'GetAllUsers',
  GetAllUsersSuccess = 'GetAllUsersSuccess',
  GetAllUsersError = 'GetAllUsersError',
}

export type UsersContextAction =
  | { type: UsersContextActionTypes.GetUser }
  | { type: UsersContextActionTypes.GetUserSuccess; payload: User }
  | { type: UsersContextActionTypes.GetUserError }
  | { type: UsersContextActionTypes.GetAllUsers }
  | { type: UsersContextActionTypes.GetAllUsersSuccess; payload: User[] }
  | { type: UsersContextActionTypes.GetAllUsersError };

export type UsersContextDispatch = (action: UsersContextAction) => void;

export type UsersContextType = [UsersContextState, UsersContextDispatch];
