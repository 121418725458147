import { AppBar, Box, Container, Toolbar } from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react';
import { Auth } from '../../lib/models/Auth';
import AuthClient from '../../lib/clients/AuthClient';
import { AuthContextActionTypes } from '../../lib/contexts/auth/AuthContext.types';
import DesktopHeader from './DesktopHeader';
import HasCompletedProfileDialog from '../HasCompletedProfileDialog';
import { HeaderPage } from './Header.types';
import MobileHeader from './MobileHeader';
import ProfileClient from '../../lib/clients/ProfileClient';
import useAuthContext from '../../lib/contexts/auth/useAuthContext';
import { useBreakpoint } from '../../lib/hooks';
import useProfileContext from '../../lib/contexts/profile/useProfileContext';
import UserClient from '../../lib/clients/UserClient';
import { UserRoles } from '../../lib/models/User';
import useUsersContext from '../../lib/contexts/users/useUsersContext';

export interface Props {}

const navPages: HeaderPage[] = [
  { label: 'header.my-packages', href: '/', roles: [] },
  { label: 'header.my-invoices', href: '/invoices', roles: [] },
  { label: 'header.all-packages', href: '/all-packages', roles: [UserRoles.ADMIN] },
  { label: 'header.all-invoices', href: '/all-invoices', roles: [UserRoles.ADMIN] },
  { label: 'header.users', href: '/users', roles: [UserRoles.ADMIN] },
  { label: 'header.resources', href: '/resources', roles: [] },
  { label: 'header.calculator', href: '/calculator', roles: [] },
];

const settings: HeaderPage[] = [
  { label: 'header.profile', href: '/profile', roles: [] },
];

const filterPagesByRole = (auth: Auth | null, pages: HeaderPage[]): HeaderPage[] => {
  if (!auth) {
    return [];
  }

  return pages.filter((page) => page.roles.length === 0 || page.roles.includes(auth.role));
};

const Header: FC<Props> = () => {
  const [showProfileModal, setShowProfileModal] = useState(false);
  const hasShownModal = useRef(false);
  const [{ auth }, authDispatch] = useAuthContext();
  const [, userDispatch] = useUsersContext();
  const [{ profile }, profileDispatch] = useProfileContext();
  const [{ user }] = useUsersContext();
  const { isMd } = useBreakpoint();
  const [allowedPages, setAllowedPages] = useState<HeaderPage[]>(filterPagesByRole(auth, navPages));
  const [allowedSettings, setAllowedSettings] = useState<HeaderPage[]>(
    filterPagesByRole(auth, settings)
  );

  useEffect(() => {
    setAllowedPages(filterPagesByRole(auth, navPages));
    setAllowedSettings(filterPagesByRole(auth, settings));
  }, [auth]);

  useEffect(() => {
    UserClient.getUser(userDispatch);
  }, []);

  useEffect(() => {
    if (auth && !profile) {
      ProfileClient.getProfile(profileDispatch);
    }
  }, [auth, profile]);

  useEffect(() => {
    if (!profile || profile.profileCompleted || hasShownModal.current) {
      return;
    }
    setShowProfileModal(true);
    hasShownModal.current = true;
  }, [profile]);

  useEffect(() => {
    if (!user || auth?.role) {
      return;
    }
    authDispatch({ type: AuthContextActionTypes.RoleUpdate, payload: { role: user.role } });
  }, [user, auth]);

  return (
    <>
      <HasCompletedProfileDialog
        show={showProfileModal}
        onClose={() => setShowProfileModal(false)}
        onSuccess={() => setShowProfileModal(false)}
      />
      <Box component="header">
        <AppBar position="sticky">
          <Container>
            <Toolbar disableGutters>
              {isMd ? (
                <MobileHeader
                  pages={allowedPages}
                  settings={allowedSettings}
                  onLogout={() => AuthClient.logout()}
                />
              ) : (
                <DesktopHeader
                  pages={allowedPages}
                  settings={allowedSettings}
                  onLogout={() => AuthClient.logout()}
                />
              )}
            </Toolbar>
          </Container>
        </AppBar>
      </Box>
    </>
  );
};

Header.displayName = 'Header';

export default Header;
