import { AuthContextProps, AuthContextState, AuthContextType } from './AuthContext.types';
import { createContext, FC, useReducer } from 'react';
import AuthClient from '../../clients/AuthClient';
import { authContextReducer } from './reducer';
import { AuthLocalStorageKeys } from '../../constants/auth';

const initialState: AuthContextState = {
  auth: AuthClient.hasClearedKeys()
    ? null
    : ({
        token: localStorage.getItem(AuthLocalStorageKeys.token) as string,
        userId: localStorage.getItem(AuthLocalStorageKeys.userId) as string,
      } as AuthContextState['auth']),
  isFetching: false,
  hasFetched: false,
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: FC<AuthContextProps> = ({ children }) => {
  const value = useReducer(authContextReducer, initialState);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthContext;
