import PackageContext from './PackageContext';
import { PackageContextType } from './PackageContext.types';
import { useContext } from 'react';

const usePackageContext = (): PackageContextType => {
  const context = useContext(PackageContext);

  if (context === undefined) {
    throw new Error('usePackageContext must be used within a PackageProvider');
  }

  return context;
};

export default usePackageContext;
