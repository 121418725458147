import {
  Box,
  Button, Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl, InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';

import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import UserClient from '../../lib/clients/UserClient';
import useUsersContext from '../../lib/contexts/users/useUsersContext';
import { UserRoles } from '../../lib/models/User';

export interface Props {
  open: boolean;
  setOpen(open: boolean): void;
}

const AddUserModal: FC<Props> = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const [, dispatch] = useUsersContext();
  const { handleSubmit, control } = useForm({
    defaultValues: {
      name: '',
      email: '',
      initials: '',
      role: UserRoles.USER,
    },
  });

  const onSubmit = (data: any) => {
    UserClient.addUser({ ...data, displayName: data.name }).then(() => {
      UserClient.getUsers(dispatch);
      setOpen(false);
    });
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{t('users.add-user')}</DialogTitle>
      <DialogContent>
        <Box component="form" id="add-package-form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <Controller
            name="name"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                margin="normal"
                required
                fullWidth
                label={t('labels.name')}
                onChange={onChange}
                value={value}
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                margin="normal"
                fullWidth
                label={t('labels.email')}
                onChange={onChange}
                value={value}
              />
            )}
          />

          <Controller
            name="initials"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                margin="normal"
                fullWidth
                label={t('labels.initials')}
                onChange={onChange}
                value={value}
              />
            )}
          />

          <FormControl fullWidth margin="normal">
            <InputLabel id="role-label">{t('labels.role')}</InputLabel>
            <Controller
              name="role"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select labelId="role-label" value={value} label="Role" onChange={onChange}>
                  <MenuItem value={UserRoles.ADMIN}>{t('users.roles.admin')}</MenuItem>
                  <MenuItem value={UserRoles.USER}>{t('users.roles.user')}</MenuItem>
                </Select>
              )}
            />
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          type="button"
          onClick={() => {
            setOpen(false);
          }}
        >
          {t('global.cancel')}
        </Button>
        <Button type="submit" variant="contained" form="add-package-form">
          {t('global.add')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddUserModal;
