import AuthContext from './AuthContext';
import { AuthContextType } from './AuthContext.types';
import { useContext } from 'react';

const useAuthContext = (): AuthContextType => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuthContext must be used within a AuthProvider');
  }

  return context;
};

export default useAuthContext;
