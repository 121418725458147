import { Box, Button, Container, Typography } from '@mui/material';
import { FC, useCallback, useEffect } from 'react';

import ProfileClient from '../../lib/clients/ProfileClient';
import ProfileDetails from '../../components/ProfileDetails';
import { useNavigate } from 'react-router-dom';
import useProfileContext from '../../lib/contexts/profile/useProfileContext';
import { useTranslation } from 'react-i18next';

export interface Props {}

const Profile: FC<Props> = () => {
  const { t } = useTranslation();
  const [, dispatch] = useProfileContext();
  const navigate = useNavigate();
  useEffect(() => {
    ProfileClient.getProfile(dispatch);
  }, []);

  const goToEdit = useCallback(() => {
    navigate('/profile/edit');
  }, [navigate]);

  return (
    <Container sx={{ py: 8 }}>
      <Box display="flex" justifyContent="space-between" marginBottom={3}>
        <Typography component="h1" variant="h5">
          {t('profile.my-profile')}
        </Typography>
        <Button onClick={goToEdit} LinkComponent="a" variant="contained">
          {t('profile.edit')}
        </Button>
      </Box>
      <ProfileDetails />
    </Container>
  );
};

Profile.displayName = 'Profile';

export default Profile;
