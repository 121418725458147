import axios, { AxiosError, AxiosResponse } from 'axios';
import AuthClient from './AuthClient';
import { AuthLocalStorageKeys } from '../constants/auth';

type AxiosObject = (AxiosResponse | AxiosError) & { response: any };
const logoutOnBadRequest = (axiosObj: AxiosObject) => {
  if (axiosObj?.response?.status !== 401) {
    return;
  }
  AuthClient.logout();
};

// Add a request interceptor
axios.interceptors.request.use((config) => {
  const token = localStorage.getItem(AuthLocalStorageKeys.token);

  if (config?.headers && token) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = token;
  }

  return config;
});

axios.interceptors.response.use(
  (response) => {
    logoutOnBadRequest(response as AxiosObject);
    return response;
  },
  (error) => {
    logoutOnBadRequest(error);
    return error;
  }
);
