import { Container } from '@mui/material';

import { FC } from 'react';
import ResourcesCard from '../../components/ResourcesCard';

export interface Props { }

const Resources: FC<Props> = () => (
  <Container sx={{ py: 8 }}>
    <ResourcesCard />
  </Container>
);

Resources.displayName = 'Info';

export default Resources;
