import { AuthContextActionTypes, AuthContextDispatch } from '../contexts/auth/AuthContext.types';
import { AuthLocalStorageKeys, LoginType } from '../constants/auth';
import { API_BASE_URL } from '../constants/api';
import { Auth } from '../models/Auth';
import axios from 'axios';
import { isNil } from 'lodash-es';

const uninterceptedAxiosInstance = axios.create();


class AuthClient {
  url = `${API_BASE_URL}/auth`;

  login(payload: LoginType, dispatch: AuthContextDispatch) {
    this.clearKeys();
    dispatch({ type: AuthContextActionTypes.Login });

    return uninterceptedAxiosInstance
      .post<Auth>(`${this.url}/login`, payload)
      .then(({ data }) => {
        localStorage.setItem(AuthLocalStorageKeys.token, data.token);
        localStorage.setItem(AuthLocalStorageKeys.userId, data.userId);
        dispatch({ type: AuthContextActionTypes.LoginSuccess, payload: data });
      })
      .catch(() => {
        dispatch({ type: AuthContextActionTypes.LoginError });
      });
  }

  clearKeys() {
    localStorage.removeItem(AuthLocalStorageKeys.token);
    localStorage.removeItem(AuthLocalStorageKeys.userId);
  }

  hasClearedKeys() {
    return (
      isNil(localStorage.getItem(AuthLocalStorageKeys.token)) ||
      isNil(localStorage.getItem(AuthLocalStorageKeys.userId))
    );
  }

  logout() {
    this.clearKeys();
    window.location.href = `${window.location.origin}/login`;
  }
}

export default new AuthClient();
