import { InvoiceStatus } from '../models/Invoice';
import { PackageStatus } from '../models/Package';

export const PackageStatusLabel = {
  [PackageStatus.PENDING_ARRIVAL]: 'package-status.pending-arrival',
  [PackageStatus.AWAITING_PICKUP]: 'package-status.awaiting-pickup',
  [PackageStatus.DELIVERED]: 'package-status.delivered',
  [PackageStatus.DONE]: 'package-status.done',
};

export const InvoiceStatusLabel = {
  [InvoiceStatus.PENDING_PAYMENT]: 'invoice-status.pending-payment',
  [InvoiceStatus.PAID]: 'invoice-status.paid',
};
