import {
  InvoiceContextActionTypes,
  InvoiceContextDispatch,
} from '../contexts/invoices/InvoiceContext.types';

import { API_BASE_URL } from '../constants/api';
import { AuthLocalStorageKeys } from '../constants/auth';
import axios from 'axios';
import { Invoice } from '../models/Invoice';

class InvoiceClient {
  url = `${API_BASE_URL}/invoices`;

  getInvoices(dispatch: InvoiceContextDispatch) {
    dispatch({ type: InvoiceContextActionTypes.GetInvoices });

    axios
      .get<Invoice[]>(`${this.url}/user/${localStorage.getItem(AuthLocalStorageKeys.userId)}`)
      .then(({ data }) => {
        dispatch({ type: InvoiceContextActionTypes.GetInvoicesSuccess, payload: data });
      })
      .catch(() => {
        dispatch({ type: InvoiceContextActionTypes.GetAllInvoicesError });
      });
  }

  getAllInvoices(dispatch: InvoiceContextDispatch) {
    dispatch({ type: InvoiceContextActionTypes.GetAllInvoices });

    axios
      .get<Invoice[]>(`${this.url}`)
      .then(({ data }) => {
        dispatch({ type: InvoiceContextActionTypes.GetAllInvoicesSuccess, payload: data });
      })
      .catch(() => {
        dispatch({ type: InvoiceContextActionTypes.GetAllInvoicesError });
      });
  }

  addInvoice(payload: Invoice, userId: string) {
    return axios.post(`${this.url}`, {
      ...payload,
      userId,
    });
  }
}

export default new InvoiceClient();
