import { FC, useEffect, useMemo } from 'react';
import { isEmpty, pickBy } from 'lodash-es';
import { INVOICE_TABLE_CONFIGURATION } from '../../constants/invoices';
import InvoiceClient from '../../lib/clients/InvoiceClient';
import PageHeader from '../../components/PageHeader';
import useInvoiceContext from '../../lib/contexts/invoices/useInvoiceContext';
import { useTranslation } from 'react-i18next';
import ViewWithTable from '../../views/ViewWithTable';

const Invoices: FC = () => {
  const { t } = useTranslation();
  const [{ invoices = [], isFetchingInvoices }, dispatch] = useInvoiceContext();

  useEffect(() => {
    InvoiceClient.getInvoices(dispatch);
  }, [dispatch]);

  const tableConfiguration = useMemo(
    () => pickBy(INVOICE_TABLE_CONFIGURATION(t), (_, key) => key !== 'userId'),
    [t]
  );

  return (
    <PageHeader
      searchEnabled={false}
      title={t('invoices.my-invoices')}
      disabledFields={isFetchingInvoices || isEmpty(invoices)}
    >
      {(props) => (
        <ViewWithTable
          {...props}
          values={invoices}
          searchProp="invoiceId"
          tableConfiguration={tableConfiguration}
          isFetching={isFetchingInvoices}
          initialOrderBy="dateAdded"
          initialSorting="desc"
          rowKey="invoiceId"
        />
      )}
    </PageHeader>
  );
};

Invoices.displayName = 'Invoices';

export default Invoices;
