import {
  PackageContextAction,
  PackageContextActionTypes,
  PackageContextState,
} from './PackageContext.types';

export const PackageContextReducer = (
  state: PackageContextState,
  action: PackageContextAction
): PackageContextState => {
  switch (action.type) {
    case PackageContextActionTypes.GetPackages:
      return { ...state, isFetchingPackages: true };
    case PackageContextActionTypes.GetPackagesSuccess:
      return { ...state, isFetchingPackages: false, packages: action.payload };
    case PackageContextActionTypes.GetPackagesError:
      return { ...state, isFetchingPackages: false };
    case PackageContextActionTypes.GetAllPackages:
      return { ...state, isFetchingAllPackages: true };
    case PackageContextActionTypes.GetAllPackagesSuccess:
      return { ...state, isFetchingAllPackages: false, allPackages: action.payload };
    case PackageContextActionTypes.GetAllPackagesError:
      return { ...state, isFetchingAllPackages: false };
    default:
      return state;
  }
};
