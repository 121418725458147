import ProfileContext from './ProfileContext';
import { ProfileContextType } from './ProfileContext.types';
import { useContext } from 'react';

const useProfileContext = (): ProfileContextType => {
  const context = useContext(ProfileContext);

  if (context === undefined) {
    throw new Error('useProfileContext must be used within a ProfileProvider');
  }

  return context;
};

export default useProfileContext;
