import { formatInvoiceStatus } from '../lib/utils/formatting';
import { Invoice } from '../lib/models/Invoice';
import { t } from 'i18next';
import { TableConfiguration } from '../components/Table';

export const INVOICE_TABLE_CONFIGURATION = (translationService: typeof t) =>
  ({
    dateAdded: {
      numeric: false,
      label: translationService('labels.date'),
      sortable: true,
    },
    userId: {
      numeric: false,
      label: translationService('labels.user-id'),
      sortable: true,
    },
    packages: {
      numeric: false,
      label: translationService('labels.packages'),
      valueFormatter: (invoice: Invoice) => invoice.packages.length
    },
    subTotal: {
      numeric: true,
      label: translationService('labels.subtotal'),
      sortable: true,
      valueFormatter: (invoice: Invoice) => translationService('format.price', { value: invoice.subTotal })
    },
    tip: {
      numeric: true,
      label: translationService('labels.tip'),
      sortable: true,
      valueFormatter: (invoice: Invoice) => translationService('format.price', { value: invoice.tip })
    },
    total: {
      numeric: true,
      label: translationService('labels.total'),
      sortable: true,
    },
    status: {
      numeric: false,
      label: translationService('labels.status'),
      sortable: true,
      valueFormatter: (invoice: Invoice) => formatInvoiceStatus(invoice.status, translationService)
    },
  } as TableConfiguration<Invoice>);
