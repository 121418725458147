import { Profile } from '../../models/Profile';
import { ReactNode } from 'react';

export interface ProfileContextState {
  profile: Profile | null;
  isFetching: boolean;
  isUpdating: boolean;
}

export interface ProfileContextProps {
  children: ReactNode;
}

export enum ProfileContextActionTypes {
  GetProfile = 'GetProfile',
  GetProfileSuccess = 'GetProfileSuccess',
  GetProfileError = 'GetProfileError',
  EditProfile = 'EditProfile',
  EditProfileSuccess = 'EditProfileSuccess',
  EditProfileError = 'EditProfileError',
}

export type ProfileContextAction =
  | { type: ProfileContextActionTypes.GetProfile }
  | { type: ProfileContextActionTypes.GetProfileSuccess; payload: Profile }
  | { type: ProfileContextActionTypes.GetProfileError }
  | { type: ProfileContextActionTypes.EditProfile; }
  | { type: ProfileContextActionTypes.EditProfileSuccess; payload: Profile }
  | { type: ProfileContextActionTypes.EditProfileError };

export type ProfileContextDispatch = (action: ProfileContextAction) => void;

export type ProfileContextType = [ProfileContextState, ProfileContextDispatch];
