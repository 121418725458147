import { t } from 'i18next';
import { TableConfiguration } from '../components/Table';
import { User } from '../lib/models/User';

export const USER_TABLE_CONFIGURATION = (translationService: typeof t) =>
  ({
    name: {
      numeric: false,
      label: translationService('labels.name'),
      sortable: true,
    },
    email: {
      numeric: false,
      label: translationService('labels.email'),
      sortable: true,
    },
    role: {
      numeric: false,
      label: translationService('labels.role'),
      valueFormatter: (user: User) => translationService(`users.roles.${user.role}`),
    },
  } as TableConfiguration<User>);
