import { FC, ReactNode } from 'react';
import { AuthProvider } from './auth/AuthContext';
import { DialogProvider } from './dialog';
import { InvoiceProvider } from './invoices/InvoiceContext';
import { PackageProvider } from './packages/PackageContext';
import { ProfileProvider } from './profile/ProfileContext';
import { UsersProvider } from './users/UsersContext';

export interface Props {
  children: ReactNode;
}

const RootProvider: FC<Props> = ({ children }) => (
  <DialogProvider>
    <AuthProvider>
      <PackageProvider>
        <ProfileProvider>
          <UsersProvider>
            <InvoiceProvider>{children}</InvoiceProvider>
          </UsersProvider>
        </ProfileProvider>
      </PackageProvider>
    </AuthProvider>
  </DialogProvider>
);

export default RootProvider;
