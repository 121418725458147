import { AuthContextAction, AuthContextActionTypes, AuthContextState } from './AuthContext.types';
import { Auth } from '../../models/Auth';

export const authContextReducer = (
  state: AuthContextState,
  action: AuthContextAction
): AuthContextState => {
  switch (action.type) {
    case AuthContextActionTypes.Login:
      return { ...state, isFetching: true };
    case AuthContextActionTypes.LoginSuccess:
      return { ...state, isFetching: false, auth: action.payload, hasFetched: true };
    case AuthContextActionTypes.LoginError:
      return { ...state, isFetching: false, hasFetched: true };
    case AuthContextActionTypes.Logout:
      return { ...state, isFetching: false, auth: null };
    case AuthContextActionTypes.RoleUpdate:
      return { ...state, auth: {...state.auth, ...action.payload as Auth } };
    default:
      return state;
  }
};
