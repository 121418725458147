import { createContext, FC, useReducer } from 'react';
import {
  InvoiceContextProps,
  InvoiceContextState,
  InvoiceContextType,
} from './InvoiceContext.types';
import { InvoiceContextReducer } from './reducer';

const initialState: InvoiceContextState = {
  invoices: [],
  isFetchingInvoices: false,
  allInvoices: [],
  isFetchingAllInvoices: false,
};

const InvoiceContext = createContext<InvoiceContextType | undefined>(undefined);

export const InvoiceProvider: FC<InvoiceContextProps> = ({ children }) => {
  const value = useReducer(InvoiceContextReducer, initialState);

  return <InvoiceContext.Provider value={value}>{children}</InvoiceContext.Provider>;
};

export default InvoiceContext;
