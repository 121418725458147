export enum UserRoles {
  ADMIN = 'admin',
  USER = 'user',
}

export interface User {
  name: string;
  email: string;
  role: UserRoles;
  uuid: string;
}

export interface UserResponse {
  users: User[];
}

export type SortableKeys = Pick<User, 'uuid' | 'name' | 'email'>;
