import { Table, TableConfiguration } from '../../components/Table';
import { useMemo, useState } from 'react';
import { PageHeaderChildProps } from '../../components/PageHeader';
import { SortByOrder } from '../../lib/constants/form';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<T, U extends keyof T>(
  order: SortByOrder,
  orderBy: U
): (a: T, b: T) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

type Props<T extends unknown, U extends keyof T> = PageHeaderChildProps & {
  values: T[];
  isFetching: boolean;
  tableConfiguration: TableConfiguration<T>;
  searchProp: keyof T;
  initialSorting: SortByOrder;
  initialOrderBy: U;
  rowKey: keyof T;
};
const ViewWithTable = <T extends unknown, U extends keyof T>({
  searchKeyWord,
  values,
  isFetching,
  tableConfiguration,
  searchProp,
  initialOrderBy,
  initialSorting,
  rowKey,
}: Props<T, U>) => {
  const [order, setOrder] = useState<SortByOrder>(initialSorting);
  const [orderBy, setOrderBy] = useState(initialOrderBy);

  const listedItems = useMemo(
    () =>
      values
        .filter((p: T) =>
          searchKeyWord
            ? (p[searchProp] as unknown as string).toLowerCase().includes(searchKeyWord.toLowerCase())
            : true
        )
        .slice()
        .sort(getComparator(order, orderBy)),
    [order, orderBy, values, searchKeyWord]
  );

  return (
    <Table
      sort={order}
      onSortChange={setOrder}
      orderBy={orderBy}
      onOrderByChange={setOrderBy}
      values={listedItems}
      tableConfiguration={tableConfiguration}
      isLoading={isFetching}
      keyProp={rowKey}
    />
  );
};

export default ViewWithTable;
