import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import AllInvoices from './pages/AllInvoices/AllInvoices';
import AllPackages from './pages/AllPackages/AllPackages';
import App from './App';
import Dashboard from './pages/Dashboard/Dashboard';
import EditProfile from './pages/Profile/EditProfile/EditProfile';
import { FC } from 'react';
import Invoices from './pages/Invoices/Invoices';
import Login from './pages/Login/Login';
import Profile from './pages/Profile/Profile';
import ProtectedRoute from './lib/helpers/ProtectedRoute';
import Resources from './pages/Resources/Resources';
import Users from './pages/Users/Users';
import Calculator from './pages/Calculator';

const AppRoutes: FC = () => (
  <Router>
    <Routes>
      <Route path="/" element={<App />}>
        <Route
          index
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route path="login" element={<Login />} />
        <Route path="profile">
          <Route
            index
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route path="edit" element={<EditProfile />} />
        </Route>
        <Route
          path="resources"
          element={
            <ProtectedRoute>
              <Resources />
            </ProtectedRoute>
          }
        />
        <Route
          path="users"
          element={
            <ProtectedRoute>
              <Users />
            </ProtectedRoute>
          }
        />
        <Route
          path="invoices"
          element={
            <ProtectedRoute>
              <Invoices />
            </ProtectedRoute>
          }
        />
        <Route
          path="all-invoices"
          element={
            <ProtectedRoute>
              <AllInvoices />
            </ProtectedRoute>
          }
        />
        <Route
          path="all-packages"
          element={
            <ProtectedRoute>
              <AllPackages />
            </ProtectedRoute>
          }
        />
      <Route
        path="calculator"
        element={
          <ProtectedRoute>
            <Calculator />
          </ProtectedRoute>
        }
      />
      </Route>
    </Routes>
  </Router>
);

export default AppRoutes;
