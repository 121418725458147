import { useEffect, useState } from 'react';

import { BREAKPOINTS } from '../constants/breakpoints';

const mappedBreakpoints = BREAKPOINTS.map(({ name, query }) => ({
  name,
  query: window.matchMedia(query),
}));

const getBreakpoint = (): { [key: string]: boolean } => {
  const matchedBreakpoints: { [key: string]: boolean } = {};
  mappedBreakpoints.forEach((bp) => {
    matchedBreakpoints[`${bp.name}`] = bp.query.matches;
  });

  return matchedBreakpoints;
};

/**
 * Helper hook that determines which breakpoints are met by the current screen size
 * Example:
 * const { isXs, isMd, isLg, isXl } = useBreakpoint();
 */
const useBreakpoint = () => {
  const [breakpoint, setBreakpoint] = useState<{ [key: string]: boolean }>(getBreakpoint());

  useEffect(() => {
    const queryHandler = () => setBreakpoint(getBreakpoint());

    mappedBreakpoints.forEach((bp) => {
      bp.query.addEventListener('change', queryHandler);
    });

    return () =>
      mappedBreakpoints.forEach((bp) => {
        bp.query.removeEventListener('change', queryHandler);
      });
  }, []);

  return breakpoint;
};

export default useBreakpoint;
