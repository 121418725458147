import {
  Box,
  Container,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Typography
} from '@mui/material';
import { isNaN, isNil, isNumber } from 'lodash-es';
import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '../../lib/utils/formatting';

type UnitType = 'lbs' | 'oz' | 'g' | 'kg';
interface CalculatorForm {
  amount?: number | string;
  unit: UnitType;
  weight?: number | string;
}

const DEFAULT_UNITS: UnitType[] = ['lbs', 'oz', 'g', 'kg'];

const DEFAULT_FORM = {
  unit: 'lbs' as UnitType,
  weight: 0,
  amount: 0,
};

const Calculator: FC = () => {
  const { t } = useTranslation();
  const [form, setForm] = useState<CalculatorForm>(DEFAULT_FORM);
  const multiplier = useMemo(() => {
    switch (form.unit) {
      case 'g':
        return 0.001;
      case 'oz':
        return 0.0283495;
      case 'lbs':
        return 0.453592;
      default:
        return 1;
    }
  }, [form.unit]);

  const isValidForm = useMemo(() => {
    const amount = parseInt(form.amount as string, 10);
    const weight = parseInt(form.weight as string, 10);
    return !isNaN(amount) && !isNaN(weight) && isNumber(amount) && isNumber(weight);
  }, [form.amount, form.weight]);

  const isValidField = useCallback((field?: string | number) => {
    if (isNil(field)) {
      return false;
    }
    const fieldValue = parseInt(field as string, 10);
    return !isNaN(fieldValue) && isNumber(fieldValue);
  }, []);

  const calculateValue = useCallback(
    (price: string, weight: string) => {
      const priceValue = parseInt(price, 10);
      const weightValue = parseInt(weight, 10);
      const subTotal = (priceValue * 0.1) + Math.ceil(weightValue * multiplier) * 12;
      let total = Math.ceil(subTotal / 5) * 5;
      if (total < 12) {
        total = 12;
      }

      return formatCurrency(total);
    },
    [multiplier]
  );

  return (
    <Container sx={{ py: 8 }}>
      <Box display="flex" justifyContent="space-between" marginBottom={3}>
        <Typography component="h1" variant="h5">
          {t('calculator.title')}
        </Typography>
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} flexDirection="column">
          <Box mb={2} display="flex" flexDirection="column">
            <TextField
              margin="normal"
              id="weight"
              required
              onChange={({ target }) =>
                setForm((prev) => ({ ...prev, weight: target.value }))
              }
              value={form.weight}
              label={t('calculator.weight')}
              {...!isValidField(form.weight) && { error: true }}
              InputProps={{
                endAdornment: <InputAdornment position="end">{form.unit}</InputAdornment>,
              }}
            />
          </Box>
          <Box mb={2} display="flex" flexDirection="column">
            <TextField
              id="units"
              margin="normal"
              label={t('calculator.unit')}
              required
              select
              value={form.unit}
              onChange={({ target }) =>
                setForm((prev) => ({ ...prev, unit: target.value as UnitType }))
              }
            >
              {DEFAULT_UNITS.map((d) => (
                <MenuItem key={d} value={d}>
                  {d}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box mb={2} display="flex" flexDirection="column">
            <TextField
              id="precio"
              margin="normal"
              onChange={({ target }) =>
                setForm((prev) => ({ ...prev, amount: target.value }))
              }
              value={form.amount}
              required
              label={t('calculator.price')}
              {...!isValidField(form.amount) && { error: true }}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
          </Box>
        </Grid>
        {isValidForm && (
          <Grid item xs={12} sm={6} flexDirection="column">
            <Typography style={{ wordWrap: 'break-word' }} variant="h4" color="red" mb={3}>
              Aproximadamente
            </Typography>
            <Typography style={{ wordWrap: 'break-word' }} variant="h3">
              {calculateValue(form.amount as string, form.weight as string)}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

Calculator.displayName = 'Calculator';

export default Calculator;
