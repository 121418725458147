import enTranslations from './locales/en/translations.json';
import esTranslations from './locales/es/translations.json';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  fallbackLng: 'es',
  lng: 'es',
  resources: {
    en: {
      translations: enTranslations,
    },
    es: {
      translations: esTranslations,
    },
  },
  interpolation: {
    format: (value, rawFormat) => {
      const [format] = (rawFormat || '').split(',').map((v) => v.trim());

      switch (format) {
        case 'price':
          return Intl.NumberFormat('en', {
            style: 'currency',
            currency: 'USD',
            currencyDisplay: 'narrowSymbol',
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }).format(value || 0);
        default:
          return value;
      }
    },
  },
  ns: ['translations'],
  defaultNS: 'translations',
});

i18n.languages = ['en', 'es'];

export default i18n;
