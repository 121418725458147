import {
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { FC, useCallback, useEffect } from 'react';

import { isEmpty, isNil, pickBy } from 'lodash-es';
import { LoadingButton } from '@mui/lab';
import { MuiTelInput } from 'mui-tel-input';
import { Profile } from '../../../lib/models/Profile';
import ProfileClient from '../../../lib/clients/ProfileClient';
import { TailSpin } from 'react-loader-spinner';
import { useDialog } from '../../../lib/contexts/dialog';
import { useNavigate } from 'react-router-dom';
import useProfileContext from '../../../lib/contexts/profile/useProfileContext';
import { useTranslation } from 'react-i18next';

const getInitialProfile = (profile: Profile) =>
  profile.expressEnabled ? profile : pickBy(profile, (_, key) => key !== 'location');

const EditProfile: FC = () => {
  const { t } = useTranslation();
  const [{ isFetching, profile, isUpdating }, dispatch] = useProfileContext();
  const navigate = useNavigate();
  const { control, handleSubmit, watch } = useForm({
    defaultValues: !isEmpty(profile) ? getInitialProfile(profile) : {},
  });
  const shouldFillAddress = watch('expressEnabled');
  const theme = useTheme();
  const { addDialog } = useDialog();

  const onSubmit = useCallback(
    (p: Partial<Profile>) => {
      ProfileClient.editProfile(dispatch, {
        id: p.id as string,
        phone: p.phone,
        expressEnabled: !!p.expressEnabled,
        location: p.location || '',
      })
        .then(() => {
          addDialog({
            message: t('global.successfully', { description: t('profile.details-edited') }),
            severity: 'success',
          });
          navigate('/');
        })
        .catch(() => addDialog({ message: t('global.something-went-wrong'), severity: 'error' }));
    },
    [t]
  );

  useEffect(() => {
    ProfileClient.getProfile(dispatch);
  }, []);

  useEffect(() => {
    if (!profile && !isFetching) {
      navigate('/');
    }
  }, [profile, isFetching]);

  return (
    <Container sx={{ py: 8 }}>
      {isFetching ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <TailSpin
            height="100"
            width="100"
            color="#1976d2"
            ariaLabel="tail-spin-loading"
            visible={isFetching}
          />
        </Box>
      ) : (
        <Box>
          <Typography mb={3} component="h1" variant="h5">
            {t('profile.edit')}
          </Typography>
          <Container maxWidth="xs" sx={{ p: 0 }}>
            <FormGroup>
              <Controller
                name="phone"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <MuiTelInput
                    margin="normal"
                    defaultCountry="CR"
                    onChange={onChange}
                    value={value?.toString()}
                    required
                    error={!!error}
                    label={t('labels.phone')}
                  />
                )}
              />
              <Controller
                name="expressEnabled"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <FormControlLabel
                    control={
                      <Checkbox required onChange={onChange} value={value} checked={value} />
                    }
                    label={t('profile.expressEnabled?')}
                    {...(!isNil(error) && {
                      slotProps: { typography: { color: theme.palette.error.dark } },
                    })}
                  />
                )}
              />
              {!!shouldFillAddress && (
                <Controller
                  name="location"
                  control={control}
                  rules={{ validate: () => shouldFillAddress, required: true }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      margin="normal"
                      onChange={onChange}
                      required
                      error={!!error}
                      value={value}
                      label={t('labels.location')}
                    />
                  )}
                />
              )}
              <Box mt={4}>
                <LoadingButton
                  loading={isUpdating}
                  fullWidth
                  variant="contained"
                  onClick={handleSubmit(onSubmit)}
                >
                  {t('profile.edit')}
                </LoadingButton>
              </Box>
            </FormGroup>
          </Container>
        </Box>
      )}
    </Container>
  );
};

EditProfile.displayName = 'Edit Profile';

export default EditProfile;
