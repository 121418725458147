import { Box, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { map } from 'lodash-es';
import { SortByOrder } from '../../lib/constants/form';
import { TableConfiguration } from './types';
import { visuallyHidden } from '@mui/utils';

interface EnhancedTableProps<T extends unknown, U extends keyof T> {
  tableConfiguration?: TableConfiguration<T>;
  onRequestSort: (event: React.MouseEvent<unknown>, property: U) => void;
  order: SortByOrder;
  orderBy: string;
}

const EnhancedTableHead = <T extends unknown, U extends keyof T>({
  tableConfiguration = {},
  order,
  orderBy,
  onRequestSort,
}: EnhancedTableProps<T, U>) => (
    <TableHead>
      <TableRow>
        {map(tableConfiguration, (headCell, key) => (
          <TableCell
            key={key}
            align={headCell?.numeric ? 'right' : 'left'}
            padding="normal"
            sortDirection={orderBy === key ? order : false}
          >
            {headCell?.sortable ? (
              <TableSortLabel
                active={orderBy === key}
                direction={orderBy === key ? order : 'asc'}
                onClick={(event) => onRequestSort(event, key as U)}
              >
                {headCell?.label}
                {orderBy === key ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell?.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );

export default EnhancedTableHead;
