import { Box, Container, Typography } from '@mui/material';

import { FC } from 'react';
import useProfileContext from '../../lib/contexts/profile/useProfileContext';
import { useTranslation } from 'react-i18next';

const ResourcesCard: FC = () => {
  const { t } = useTranslation();
  const [{ profile }] = useProfileContext();

  return (
    profile && (
      <Container maxWidth="xs" sx={{ p: 0 }}>
        <Box border={2} borderColor="grey.800" borderRadius="3px" p={4}>
          <Box mb={4}>
            <Typography variant="h5" mb={2}>
              {t('resources.address')}
            </Typography>
            <Box sx={{ mb: 1 }}>
              <Typography variant="subtitle2" color="grey.700" fontWeight="700">
                {t('resources.labels.name')}
              </Typography>
              <Typography variant="body1" sx={{ overflowWrap: 'break-word' }}>
                {t('resources.details.name', { initials: profile.initials })}
              </Typography>
            </Box>
            <Box sx={{ mb: 1 }}>
              <Typography variant="subtitle2" color="grey.700" fontWeight="700">
                {t('resources.labels.address')}
              </Typography>
              <Typography variant="body1" sx={{ overflowWrap: 'break-word' }}>
                {t('resources.details.address')}
              </Typography>
            </Box>
            <Box sx={{ mb: 1 }}>
              <Typography variant="subtitle2" color="grey.700" fontWeight="700">
                {t('resources.labels.city')}
              </Typography>
              <Typography variant="body1" sx={{ overflowWrap: 'break-word' }}>
                {t('resources.details.city')}
              </Typography>
            </Box>
            <Box sx={{ mb: 1 }}>
              <Typography variant="subtitle2" color="grey.700" fontWeight="700">
                {t('resources.labels.state')}
              </Typography>
              <Typography variant="body1" sx={{ overflowWrap: 'break-word' }}>
                {t('resources.details.state')}
              </Typography>
            </Box>
            <Box sx={{ mb: 1 }}>
              <Typography variant="subtitle2" color="grey.700" fontWeight="700">
                {t('resources.labels.postal-code')}
              </Typography>
              <Typography variant="body1" sx={{ overflowWrap: 'break-word' }}>
                {t('resources.details.postal-code')}
              </Typography>
            </Box>
            <Box sx={{ mb: 1 }}>
              <Typography variant="subtitle2" color="grey.700" fontWeight="700">
                {t('resources.labels.phone')}
              </Typography>
              <Typography variant="body1" sx={{ overflowWrap: 'break-word' }}>
                {t('resources.details.phone')}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Typography variant="h5" mb={2}>
              {t('resources.invoice-account')}
            </Typography>
            <Box sx={{ mb: 1 }}>
              <Typography variant="subtitle2" color="grey.700" fontWeight="700">
                {t('resources.labels.account-number')}
              </Typography>
              <Typography variant="body1" sx={{ overflowWrap: 'break-word' }}>
                {t('resources.invoice.account-number')}
              </Typography>
            </Box>
            <Box sx={{ mb: 1 }}>
              <Typography variant="subtitle2" color="grey.700" fontWeight="700">
                {t('resources.labels.account-iban')}
              </Typography>
              <Typography variant="body1" sx={{ overflowWrap: 'break-word' }}>
                {t('resources.invoice.account-iban')}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Container>
    )
  );
};

export default ResourcesCard;
