import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@mui/material';
import { isFunction, map } from 'lodash-es';
import { MouseEvent, useCallback, useMemo, useState } from 'react';
import { TableColumn, TableConfiguration } from './types';
import EnhancedTableHead from './EnhancedTableHead';
import { SortByOrder } from '../../lib/constants/form';
import { TailSpin } from 'react-loader-spinner';

type Props<T extends unknown, U extends keyof T> = {
  values: T[];
  sort: SortByOrder;
  onSortChange: (newOrder: SortByOrder) => void;
  orderBy: U;
  onOrderByChange: (newOrder: U) => void;
  tableConfiguration?: TableConfiguration<T>;
  isLoading?: boolean;
  keyProp: keyof T;
};
const TableComponent = <T extends unknown, U extends keyof T>({
  values = [],
  tableConfiguration,
  sort,
  orderBy,
  onOrderByChange,
  onSortChange,
  keyProp,
  isLoading = false,
}: Props<T, U>) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = useCallback(
    (_: MouseEvent<unknown>, property: U) => {
      const isAsc = orderBy === property && sort === 'asc';
      onSortChange(isAsc ? 'desc' : 'asc');
      onOrderByChange(property);
    },
    [orderBy, sort]
  );

  const handleChangePage = useCallback((_: unknown, newPage: number) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = useMemo(
    () => (page > 0 ? Math.max(0, (1 + page) * rowsPerPage - values.length) : 0),
    [values.length]
  );

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <TailSpin
          height="100"
          width="100"
          color="#1976d2"
          ariaLabel="tail-spin-loading"
          visible={isLoading}
        />
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
            <EnhancedTableHead
              tableConfiguration={tableConfiguration}
              order={sort}
              orderBy={orderBy as string}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {values
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((value: T) => (
                  <TableRow key={value[keyProp] as unknown as string}>
                    {map(
                      tableConfiguration ?? {},
                      ({ cellProps = {}, valueFormatter, label }: TableColumn<T>, key: keyof T) => (
                        <TableCell key={label} {...cellProps}>
                          {
                            (isFunction(valueFormatter)
                              ? valueFormatter(value)
                              : value?.[key]) as string
                          }
                        </TableCell>
                      )
                    )}
                  </TableRow>
                ))}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={values.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

export default TableComponent;
