import { FC, useEffect, useMemo } from 'react';
import { isEmpty } from 'lodash-es';
import { PACKAGE_TABLE_CONFIGURATION } from '../../constants/packages';
import PackageClient from '../../lib/clients/PackageClient';
import PageHeader from '../../components/PageHeader';
import usePackageContext from '../../lib/contexts/packages/usePackageContext';
import { useTranslation } from 'react-i18next';
import ViewWithTable from '../../views/ViewWithTable';

const AllPackages: FC = () => {
  const { t } = useTranslation();
  const [{ allPackages = [], isFetchingAllPackages }, dispatch] = usePackageContext();
  
  useEffect(() => {
    PackageClient.getAllPackages(dispatch);
  }, [dispatch]);

  const tableConfiguration = useMemo(
    () =>
      PACKAGE_TABLE_CONFIGURATION(t),
    [t]
  );

  return (
    <PageHeader
        searchEnabled
        title={t('packages.all-packages')}
        disabledFields={isFetchingAllPackages || isEmpty(allPackages)}
      >
        {(props) => (
          <ViewWithTable
          {...props}
          values={allPackages}
          searchProp="trackingNumber"
          tableConfiguration={tableConfiguration}
          isFetching={isFetchingAllPackages}
          initialOrderBy="dateAdded"
          initialSorting="desc"
          rowKey="trackingNumber"
        />
        )}
      </PageHeader>
  );
};

AllPackages.displayName = 'AllPackages';

export default AllPackages;
