import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';

import { FC } from 'react';
import PackageClient from '../../lib/clients/PackageClient';
import usePackageContext from '../../lib/contexts/packages/usePackageContext';
import { useTranslation } from 'react-i18next';

export interface Props {
  open: boolean;
  setOpen(open: boolean): void;
}

const AddPackageModal: FC<Props> = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const [, dispatch] = usePackageContext();
  const { handleSubmit, control, reset } = useForm();

  const onSubmit = (data: any) => {
    PackageClient.addPackage(data).then(() => {
      PackageClient.getPackages(dispatch);
      setOpen(false);
      reset();
    });
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{t('packages.add-package')}</DialogTitle>
      <DialogContent>
        <Box component="form" id="add-package-form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <Controller
            name="trackingNumber"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                margin="normal"
                required
                fullWidth
                label={t('labels.tracking-number')}
                onChange={onChange}
                value={value}
              />
            )}
          />
          <Controller
            name="description"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                margin="normal"
                fullWidth
                label={t('labels.description')}
                onChange={onChange}
                value={value}
                multiline
                rows={3}
              />
            )}
          />
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              {/* <Controller
                name="price"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    margin="normal"
                    fullWidth
                    label={t('labels.price-with-sign')}
                    onChange={onChange}
                    value={value}
                    type="number"
                  />
                )}
              /> */}
            </Grid>
            <Grid item xs={12} md={6}>
              {/* <Controller
                name="weight"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    margin="normal"
                    fullWidth
                    label={t('labels.weight')}
                    onChange={onChange}
                    value={value}
                    type="number"
                  />
                )}
              /> */}
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          type="button"
          onClick={() => {
            setOpen(false);
          }}
        >
          {t('global.cancel')}
        </Button>
        <Button type="submit" variant="contained" form="add-package-form">
          {t('global.add')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddPackageModal;
