import { Package } from '../../models/Package';
import { ReactNode } from 'react';

export interface PackageContextState {
  packages: Package[];
  isFetchingPackages: boolean;
  allPackages: Package[];
  isFetchingAllPackages: boolean;
}

export interface PackageContextProps {
  children: ReactNode;
}

export enum PackageContextActionTypes {
  GetPackages = 'GetPackages',
  GetPackagesSuccess = 'GetPackagesSuccess',
  GetPackagesError = 'GetPackagesError',
  GetAllPackages = 'GetAllPackages',
  GetAllPackagesSuccess = 'GetAllPackagesSuccess',
  GetAllPackagesError = 'GetAllPackagesError',
}

export type PackageContextAction =
  | { type: PackageContextActionTypes.GetPackages }
  | { type: PackageContextActionTypes.GetPackagesSuccess; payload: Package[] }
  | { type: PackageContextActionTypes.GetPackagesError }
  | { type: PackageContextActionTypes.GetAllPackages }
  | { type: PackageContextActionTypes.GetAllPackagesSuccess; payload: Package[] }
  | { type: PackageContextActionTypes.GetAllPackagesError };

export type PackageContextDispatch = (action: PackageContextAction) => void;

export type PackageContextType = [PackageContextState, PackageContextDispatch];
