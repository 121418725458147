import { InvoiceStatusLabel, PackageStatusLabel } from '../constants/labels';

import dayjs from 'dayjs';
import { InvoiceStatus } from '../models/Invoice';
import { PackageStatus } from '../models/Package';
import { Profile } from '../models/Profile';
import { TFunction } from 'i18next';

const currencyFormatter = Intl.NumberFormat('en', {
  style: 'currency',
  currency: 'USD',
  currencyDisplay: 'narrowSymbol',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

export const formatInitials = (profile: Profile): string => {
  let initials = '??';
  if (profile.name) {
    const [first = '', last = ''] = profile.name.split(' ');
    initials =
      `${first?.slice(0, 1)?.toUpperCase()}${last?.slice(0, 1)?.toUpperCase()}` || initials;
  }
  return initials;
};

export const formatDate = (date: string | number) => dayjs(date).format('DD/MM/YYYY');

export const formatPackageStatus = (status: PackageStatus, t: TFunction) =>
  t(PackageStatusLabel[status]);

export const formatInvoiceStatus = (status: InvoiceStatus, t: TFunction) =>
  t(InvoiceStatusLabel[status]);

export const formatCurrency = (value: number) => currencyFormatter.format(value ?? 0);
